package cbconnectit.portfolio.web.svg

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.dom.svg.Path
import com.varabyte.kobweb.compose.dom.svg.SVGFillRule
import com.varabyte.kobweb.compose.dom.svg.Svg
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.CSSColorValue

@Composable
fun mobileDevelopmentSvg(
    fill: CSSColorValue,
    modifier: Modifier = Modifier,
) = Svg(attrs = modifier.toAttrs {
    width(14)
    height(24)
    viewBox(0,0,14,24)
}) {
    Path {
        fillRule(SVGFillRule.EvenOdd)
        d("M2.5 0.375H7H11.5C11.9971 0.375558 12.4737 0.573282 12.8252 0.924791C13.1767 1.2763 13.3744 1.75289 13.375 2.25V21.75C13.3744 22.2471 13.1767 22.7237 12.8252 23.0752C12.4737 23.4267 11.9971 23.6244 11.5 23.625H2.5C2.00289 23.6244 1.5263 23.4267 1.17479 23.0752C0.823282 22.7237 0.625558 22.2471 0.625 21.75V2.25C0.625558 1.75289 0.823282 1.2763 1.17479 0.924791C1.5263 0.573282 2.00289 0.375558 2.5 0.375ZM9.96426 1.5494H4.07006H2.87752C2.57925 1.54973 2.2933 1.66836 2.08239 1.87927C1.87148 2.09018 1.75285 2.37613 1.75252 2.6744V12.0247V21.375C1.75285 21.6733 1.87148 21.9592 2.08239 22.1701C2.2933 22.381 2.57925 22.4997 2.87752 22.5H7.01716H11.1568C11.4551 22.4997 11.741 22.381 11.9519 22.1701C12.1628 21.9592 12.2815 21.6733 12.2818 21.375V12.0247V2.6744C12.2815 2.37613 12.1628 2.09018 11.9519 1.87927C11.741 1.66836 11.4551 1.54973 11.1568 1.5494H9.96426Z")
        fill(fill)
    }
    Path {
        d("M7 2.56349C6.83424 2.56349 6.67527 2.62934 6.55806 2.74655C6.44085 2.86376 6.375 3.02273 6.375 3.18849C6.375 3.35425 6.44085 3.51322 6.55806 3.63043C6.67527 3.74764 6.83424 3.81349 7 3.81349C7.16576 3.81349 7.32473 3.74764 7.44194 3.63043C7.55915 3.51322 7.625 3.35425 7.625 3.18849C7.625 3.02273 7.55915 2.86376 7.44194 2.74655C7.32473 2.62934 7.16576 2.56349 7 2.56349Z")
        fill(fill)
    }
}