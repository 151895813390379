package cbconnectit.portfolio.web.svg

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.dom.svg.Path
import com.varabyte.kobweb.compose.dom.svg.Svg
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.CSSColorValue

@Composable
fun backendDevelopmentSvg(
    fill: CSSColorValue,
    modifier: Modifier = Modifier,
) = Svg(attrs = modifier.toAttrs {
    width(32)
    height(30)
    viewBox(0,0,32,30)
}) {
    Path {
        d("M24.861 22.0864C22.9937 22.0864 21.4065 21.7939 20.0994 21.2088C18.7923 20.6237 18.1388 19.9203 18.1388 19.0987C18.1388 18.2771 18.7923 17.5738 20.0994 16.9887C21.4065 16.4036 22.9937 16.1111 24.861 16.1111C26.7283 16.1111 28.3155 16.4036 29.6226 16.9887C30.9297 17.5738 31.5832 18.2771 31.5832 19.0987C31.5832 19.9203 30.9297 20.6237 29.6226 21.2088C28.3155 21.7939 26.7283 22.0864 24.861 22.0864ZM24.861 25.821C22.9937 25.821 21.4065 25.5284 20.0994 24.9433C18.7923 24.3583 18.1388 23.6549 18.1388 22.8333V20.966C18.1388 21.5138 18.394 21.9776 18.9044 22.3575C19.4148 22.737 20.0248 23.0449 20.7343 23.2815C21.4439 23.518 22.1816 23.689 22.9474 23.7946C23.7128 23.9006 24.3506 23.9537 24.861 23.9537C25.3714 23.9537 26.0093 23.9006 26.7746 23.7946C27.5404 23.689 28.2781 23.518 28.9877 23.2815C29.6973 23.0449 30.3073 22.737 30.8177 22.3575C31.328 21.9776 31.5832 21.5138 31.5832 20.966V22.8333C31.5832 23.6549 30.9297 24.3583 29.6226 24.9433C28.3155 25.5284 26.7283 25.821 24.861 25.821ZM24.861 29.5555C22.9937 29.5555 21.4065 29.263 20.0994 28.6779C18.7923 28.0928 18.1388 27.3895 18.1388 26.5679V24.7006C18.1388 25.2483 18.394 25.7122 18.9044 26.0921C19.4148 26.4715 20.0248 26.7795 20.7343 27.016C21.4439 27.2525 22.1816 27.4238 22.9474 27.5299C23.7128 27.6355 24.3506 27.6882 24.861 27.6882C25.3714 27.6882 26.0093 27.6355 26.7746 27.5299C27.5404 27.4238 28.2781 27.2525 28.9877 27.016C29.6973 26.7795 30.3073 26.4715 30.8177 26.0921C31.328 25.7122 31.5832 25.2483 31.5832 24.7006V26.5679C31.5832 27.3895 30.9297 28.0928 29.6226 28.6779C28.3155 29.263 26.7283 29.5555 24.861 29.5555Z")
        fill(fill)
    }
    Path {
        d("M10.3239 18.0569L7.58797 15.4999L10.3239 12.9429C10.5975 12.6872 10.5975 12.2807 10.3239 12.025C10.0503 11.7693 9.61534 11.7693 9.34175 12.025L6.12182 15.0344C5.84823 15.2901 5.84823 15.7032 6.12182 15.9589L9.34175 18.9748C9.61534 19.2305 10.0503 19.2305 10.3239 18.9748C10.5975 18.7191 10.5975 18.3126 10.3239 18.0569ZM14.9538 18.0569L17.6897 15.4999L14.9538 12.9429C14.6802 12.6872 14.6802 12.2807 14.9538 12.025C15.2274 11.7693 15.6624 11.7693 15.9359 12.025L19.1559 15.0344C19.4295 15.2901 19.4295 15.7032 19.1559 15.9589L15.9359 18.9748C15.6624 19.2305 15.2274 19.2305 14.9538 18.9748C14.6802 18.7191 14.6802 18.3126 14.9538 18.0569Z")
        fill(fill)
    }
    Path {
        d("M22.4166 0.833252H21.1944H4.08329H2.86107C1.51663 0.833252 0.416626 1.93325 0.416626 3.2777V22.8333C0.416626 24.1777 1.51663 25.2777 2.86107 25.2777H16.9166V22.8333H4.08329C3.41107 22.8333 2.86107 22.2833 2.86107 21.611V6.94436H22.4166V14.8888H24.8611V3.2777C24.8611 1.93325 23.7611 0.833252 22.4166 0.833252Z")
        fill(fill)
    }
}
