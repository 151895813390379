import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { cbconnectit.portfolio.web.pages.HomePage() }
        ctx.router.register("/projects/") { cbconnectit.portfolio.web.pages.projects.ProjectsPage()
                }
        ctx.router.register("/services/") { cbconnectit.portfolio.web.pages.services.ServicesPage()
                }
        ctx.router.register("/services/service/") {
                cbconnectit.portfolio.web.pages.services.service.ServicePage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.pages.projects.ProjectTagLinkStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.AboutImageStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.AboutTextStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.BackToTopButtonStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.InputStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.NavigationItemStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.LogoStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.SocialLinkStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.MainButtonStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.MainImageStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.PortfolioSectionStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.PortfolioArrowIconStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.ProjectNameStyle)
        ctx.theme.registerComponentStyle(cbconnectit.portfolio.web.styles.ServicesTypeCardStyle)
        ctx.theme.registerComponentVariants(cbconnectit.portfolio.web.components.PrimaryButtonVariant)
        ctx.theme.registerComponentVariants(cbconnectit.portfolio.web.components.NormalButtonVariant)
        ctx.theme.registerComponentVariants(cbconnectit.portfolio.web.components.TextPrimaryButtonVariant)
        cbconnectit.portfolio.web.updateTheme(ctx)
        cbconnectit.portfolio.web.registerGlobalStyles(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(AppGlobalsLocal provides mapOf("title" to "Portfolio Web Compose",
                "BASE_URL" to "https://cbconnectit-portfolio-backend.onrender.com")) {
            cbconnectit.portfolio.web.MyApp {
                router.renderActivePage { renderWithDeferred { it() } }
            }
        }
    }
}
