package cbconnectit.portfolio.web.models.enums

import cbconnectit.portfolio.web.utils.Res

enum class TechnologyStacks(val icon: String) {
    Java(Res.Icon.TechStack.java),
    Kotlin(Res.Icon.TechStack.kotlin),
    Php(Res.Icon.TechStack.php),
    Firebase(Res.Icon.TechStack.firebase),
    Android(Res.Icon.TechStack.android),
    MySql(Res.Icon.TechStack.mysql),
    Kobweb(Res.Icon.TechStack.kobweb),
    Node(Res.Icon.TechStack.node),
    Angular(Res.Icon.TechStack.angular)
}