package cbconnectit.portfolio.web.svg

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.dom.svg.Path
import com.varabyte.kobweb.compose.dom.svg.SVGFillRule
import com.varabyte.kobweb.compose.dom.svg.Svg
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.CSSColorValue

@Composable
fun frontendDevelopmentSvg(
    fill: CSSColorValue,
    modifier: Modifier = Modifier,
) = Svg(attrs = modifier.toAttrs {
    width(37)
    height(36)
    viewBox(0,0,37,36)
}) {
    Path {
        d("M32.25 3H5.25C3.6 3 2.25 4.35 2.25 6V24C2.25 25.65 3.6 27 5.25 27H15.75V30H14.25C13.425 30 12.75 30.675 12.75 31.5C12.75 32.325 13.425 33 14.25 33H23.25C24.075 33 24.75 32.325 24.75 31.5C24.75 30.675 24.075 30 23.25 30H21.75V27H32.25C33.9 27 35.25 25.65 35.25 24V6C35.25 4.35 33.9 3 32.25 3ZM30.75 24H6.75C5.925 24 5.25 23.325 5.25 22.5V7.5C5.25 6.675 5.925 6 6.75 6H30.75C31.575 6 32.25 6.675 32.25 7.5V22.5C32.25 23.325 31.575 24 30.75 24Z")
        fill(fill)
    }
    Path {
        fillRule(SVGFillRule.EvenOdd)
        d("M25.7828 9H25.2418H11.9823H11.4413C10.8462 9 10.3593 9.49091 10.3593 10.0909L10.3539 21C10.3539 21.6 10.8408 22.0909 11.4359 22.0909H25.7774C26.3725 22.0909 26.8593 21.6 26.8593 21L26.8648 10.0909C26.8648 9.49091 26.3779 9 25.7828 9ZM11.9769 21H25.2364C25.5339 21 25.7774 20.7545 25.7774 20.4545L25.7828 11.7273H11.4413L11.4359 20.4545C11.4359 20.7545 11.6793 21 11.9769 21Z")
        fill(fill)
    }
    Path {
        d("M16.8511 18.1091L14.7413 15.9818L16.8511 13.8545C17.0621 13.6418 17.0621 13.3036 16.8511 13.0909C16.6402 12.8782 16.3048 12.8782 16.0938 13.0909L13.6107 15.5945C13.3997 15.8073 13.3997 16.1509 13.6107 16.3636L16.0938 18.8727C16.3048 19.0855 16.6402 19.0855 16.8511 18.8727C17.0621 18.66 17.0621 18.3218 16.8511 18.1091ZM20.4216 18.1091L22.5315 15.9818L20.4216 13.8545C20.2107 13.6418 20.2107 13.3036 20.4216 13.0909C20.6326 12.8782 20.968 12.8782 21.179 13.0909L23.6621 15.5945C23.8731 15.8073 23.8731 16.1509 23.6621 16.3636L21.179 18.8727C20.968 19.0855 20.6326 19.0855 20.4216 18.8727C20.2107 18.66 20.2107 18.3218 20.4216 18.1091Z")
        fill(fill)
    }
}